import React from 'react'
import ProfileBgAnimation from '../ProfileBgAnimation'
import { ProfileContainer, ProfileBg, ProfileLeftContainer, Img, ProfileRightContainer, ProfileInnerContainer, TextLoop, Title, Span, SubTitle, ResumeButton } from './ProfileStyles'
import ProfileImg from '../../Images/ProfilePic.png'
import Typewriter from 'typewriter-effect'
import { Bio } from '../../Data/Constants'

const ProfilePic = () => {
    return (
        <div id="about">
            <ProfileContainer>
                <ProfileBg>
                    <ProfileBgAnimation />
                </ProfileBg>
                <ProfileInnerContainer>
                    <ProfileLeftContainer id="Left">
                        <Title>Hi, I'm <br/> {Bio.name}</Title>
                        <TextLoop>
                            I am a
                            <Span>
                                <Typewriter
                                    options={{
                                        strings: Bio.roles,
                                        autoStart: true,
                                        loop: true
                                    }}
                                />
                            </Span>
                        </TextLoop>
                        <SubTitle>{Bio.description}</SubTitle>
                        <ResumeButton href={Bio.resume} target='display'>View Resume</ResumeButton>
                    </ProfileLeftContainer>
                    <ProfileRightContainer id="Right">
                        <Img src={ProfileImg} alt="Profile Image" />
                    </ProfileRightContainer>
                </ProfileInnerContainer>
            </ProfileContainer>
        </div>
    )
}

export default ProfilePic