import React from 'react'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import { Snackbar } from '@mui/material'
import { Container, Wrapper, Title, Desc, ContactForm, ContactTitle, ContactInput, ContactInputMessage, ContactButton } from './ContactStyles'


const Contact = () => {

    //hooks
    const [open, setOpen] = React.useState(false);
    const form = useRef();
  
    const handleSubmit = (e) => {
      e.preventDefault();
      emailjs.sendForm('service_b3igmp5', 'template_zn2yexf', form.current, 'aNbWEiNnWKEQq_NIK')
        .then((result) => {
          setOpen(true);
          form.current.reset();
        }, (error) => {
          console.log(error.text);
        });
    }
  
    return (
      <Container>
        <Wrapper>
          <Title>Contact</Title>
          <Desc>Feel free to contact me with any questions or opportunities!</Desc>
          <ContactForm ref={form} onSubmit={handleSubmit}>
            <ContactTitle>Contact Me</ContactTitle>
            <ContactInput placeholder="Your Email" name="from_email" />
            <ContactInput placeholder="Your Name" name="from_name" />
            <ContactInput placeholder="Subject" name="subject" />
            <ContactInputMessage placeholder="Message" rows="4" name="message" />
            <ContactButton type="submit" value="Send" />
          </ContactForm>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={()=>setOpen(false)}
            message="Email sent successfully!"
            severity="success"
          />
        </Wrapper>
      </Container>
    )
  }
  
  export default Contact