import React from 'react'
import { Nav, NavLink, NavbarContainer, Span, NavLogo, NavItems, GitHubButton, ButtonContainer, MobileIcon, MobileMenu, MobileLink } from './NavbarStyledComponent'
import { FaBars } from 'react-icons/fa';
import { Bio } from '../../Data/Constants';
import { useTheme } from 'styled-components';
import logo from '../../Images/terminal.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme();
  const navbarHeight = 70; // Set your navbar height here (or calculate dynamically if needed)

  // Scroll to the specified section with an offset
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY - navbarHeight; // Subtract the navbar height
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Nav>
      <NavbarContainer>
        <NavLogo>
          <a href="#about"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} // Scroll to top of the page
            style={{ 
              display: "flex",
              alignItems: "center", 
              color: "white", 
              cursor: 'pointer',
              textDecoration: 'none'
            }}>
            <img id="logo" src={logo} alt="Logo" />
            <Span>Jacob Armstrong</Span>
          </a>
        </NavLogo>
        <MobileIcon>
          <FaBars onClick={() => setIsOpen(!isOpen)} />
        </MobileIcon>
        <NavItems>
          <NavLink onClick={() => scrollToSection('about')}>About</NavLink>
          <NavLink onClick={() => scrollToSection('skills')}>Skills</NavLink>
          <NavLink onClick={() => scrollToSection('education')}>Education</NavLink>
          <NavLink onClick={() => scrollToSection('projects')}>Projects</NavLink>
          <NavLink onClick={() => scrollToSection('experience')}>Experience</NavLink>
        </NavItems>
        <ButtonContainer>
          <GitHubButton href={Bio.github} target="_blank">Github Profile</GitHubButton>
        </ButtonContainer>
        {
          isOpen && (
            <MobileMenu isOpen={isOpen}>
              <MobileLink onClick={() => { setIsOpen(!isOpen); scrollToSection('about'); }}>About</MobileLink>
              <MobileLink onClick={() => { setIsOpen(!isOpen); scrollToSection('skills'); }}>Skills</MobileLink>
              <MobileLink onClick={() => { setIsOpen(!isOpen); scrollToSection('experience'); }}>Experience</MobileLink>
              <MobileLink onClick={() => { setIsOpen(!isOpen); scrollToSection('projects'); }}>Projects</MobileLink>
              <MobileLink onClick={() => { setIsOpen(!isOpen); scrollToSection('education'); }}>Education</MobileLink>
              <GitHubButton style={{ padding: '10px 16px', background: `${theme.primary}`, color: 'white', width: 'max-content' }} href={Bio.github} target="_blank">Github Profile</GitHubButton>
            </MobileMenu>
          )
        }
      </NavbarContainer>
    </Nav>
  );
}

export default Navbar;
