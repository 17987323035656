import './App.css'
import styled, { ThemeProvider } from 'styled-components'
import { useState } from 'react';
import { darkTheme } from './Utils/Theme.js'
import { BrowserRouter as Router } from 'react-router-dom'

import Navbar from './Components/Navbar'
import Profile from './Components/Profile/index.js'
import Education from './Components/Education'
import Skills from './Components/Skills'
import Projects from './Components/Projects'
import ProjectDetails from './Components/ProjectDetails'
import Experience from './Components/Experience'
import Contact from './Components/Contact'
import Footer from './Components/Footer'

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 0, 0.15) 0%, rgba(204, 0, 0, 0) 50%), linear-gradient(141.27deg, rgba(220, 79, 107, 0) 50%, rgba(220, 79, 107, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`

function App() {
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  console.log(openModal)
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Navbar />
        <Body>
          <Profile />
          <Wrapper>
            <Skills />
            <Education />
          </Wrapper>
          <Projects openModal={openModal} setOpenModal={setOpenModal} />
          <Wrapper>
            <Experience />
            <Contact />
          </Wrapper>
          <Footer />
          {openModal.state &&
            <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
          }
        </Body>
      </Router>
    </ThemeProvider>
  )
}

export default App
